@font-face {
    font-family: 'Spot Outline';
    src: url('Spot-Outline.eot');
    src: url('Spot-Outline.eot?#iefix') format('embedded-opentype'),
        url('Spot-Outline.woff2') format('woff2'),
        url('Spot-Outline.woff') format('woff'),
        url('Spot-Outline.ttf') format('truetype'),
        url('Spot-Outline.svg#Spot-Outline') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Spot';
    src: url('Spot-Italic.eot');
    src: url('Spot-Italic.eot?#iefix') format('embedded-opentype'),
        url('Spot-Italic.woff2') format('woff2'),
        url('Spot-Italic.woff') format('woff'),
        url('Spot-Italic.ttf') format('truetype'),
        url('Spot-Italic.svg#Spot-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Spot Outline';
    src: url('Spot-OutlineItalic.eot');
    src: url('Spot-OutlineItalic.eot?#iefix') format('embedded-opentype'),
        url('Spot-OutlineItalic.woff2') format('woff2'),
        url('Spot-OutlineItalic.woff') format('woff'),
        url('Spot-OutlineItalic.ttf') format('truetype'),
        url('Spot-OutlineItalic.svg#Spot-OutlineItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Spot Normal';
    src: url('Spot-Normal.eot');
    src: url('Spot-Normal.eot?#iefix') format('embedded-opentype'),
        url('Spot-Normal.woff2') format('woff2'),
        url('Spot-Normal.woff') format('woff'),
        url('Spot-Normal.ttf') format('truetype'),
        url('Spot-Normal.svg#Spot-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.eot');
    src: url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff'),
        url('Inter-Regular.ttf') format('truetype'),
        url('Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titan One';
    src: url('TitanOne.eot');
    src: url('TitanOne.eot?#iefix') format('embedded-opentype'),
        url('TitanOne.woff2') format('woff2'),
        url('TitanOne.woff') format('woff'),
        url('TitanOne.ttf') format('truetype'),
        url('TitanOne.svg#TitanOne') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


